import { push } from 'connected-react-router';
import {
  clearSection,
  clearSession,
  dmpCommandFailureContextualizedType,
  dmpCommandSuccessContextualizedType,
  initApplication,
  initApplicationFailure,
  initApplicationSuccess,
  resetApplication,
  setESConfiguration,
  setSessionKo,
} from 'dmpconnectjsapp-base/actions';
import {
  formatCreateEsTLSiConnector,
  formatCreateTLSiConnector,
  formatGetCardParams,
  formatOpenSessionParams,
} from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';

import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { API_TYPES, apiSections, dmpconnectActionConstants } from 'dmpconnectjsapp-base/constants';

import { errorTypes, softwareErrors } from 'dmpconnectjsapp-base/errors';
import {
  getApiType,
  getApplicationId,
  getConfigurationValue,
  getCurrentPathname,
  getdmpconnectConnectorConfig,
  getDmpconnectCPxConfiguration,
  getEsUser,
  getInteropCodesFromState,
  getProductionMode,
  getRemoteControlAppName,
  getSessionId,
  getUserConfiguration,
  isRemoteControlActive,
} from 'dmpconnectjsapp-base/helpers/accessors';
import { sessionExists } from 'dmpconnectjsapp-base/helpers/checks/sessionExists';
import { esLoginTypes } from 'dmpconnectjsapp-base/reducers/dmpconnectESConfiguration';
import { getSavedPinCode, savePinCode } from 'dmpconnectjsapp-base/sagas/errorHandlersSagas';
import { encodeIns } from 'dmpconnectjsapp-base/utils/insUtils';
import {
  call, put, select, take,
} from 'redux-saga/effects';
import env from '../../envVariables';

import {
  esLoginSuccess, setLoginStepTwo, startCpxMonitoring, stopCpxMonitoring,
} from '../actions';
import { getAction } from '../actions/dmpconnectActions';

import { setModalError, setPanel } from '../actions/dmpconnectApplicationActions';

import {
  clearLoginReferer,
  esLoginFailed,
  login,
  loginSuccess,
  logout,
  logoutSuccess,
  setEsUser,
  setLoginReferer,
} from '../actions/dmpconnectUserActions';
import { dmpconnectUserActionConstants, PANELS_INDEX } from '../constants';

import { cpxCardTypes } from '../constants/dmpConstants';
import { createError, createErrorDetails } from '../errors';

import {
  getDmpconnectCpxConfiguration,
  getDmpconnectESConfiguration,
  getLoginReferer,
  isUserLoggedIn as isUserLoggedInAccessor,
} from '../helpers';
import { exportCpxInfo } from '../helpers/insi/cpxInfoExport';

const isAppInitialized = ({ dmpconnectInit }) => dmpconnectInit.applicationInitialized;
const IsUserLoggedIn = state => isUserLoggedInAccessor(state);
const getEsCertificate = ({ dmpconnectESConfiguration }) => getConfigurationValue('esCertificate', dmpconnectESConfiguration);
const getEsId = ({ dmpconnectESConfiguration }) => getConfigurationValue('es_id', dmpconnectESConfiguration);
const getLoginType = ({ dmpconnectESConfiguration }) => getConfigurationValue('loginType', dmpconnectESConfiguration);
const getDcParams = ({ dmpconnectConnectorConfig }) => getConfigurationValue('dcparams', dmpconnectConnectorConfig);
const getTseActive = ({ dmpconnectTSEConfiguration }) => getConfigurationValue('active', dmpconnectTSEConfiguration);
const getTseWhoami = ({ dmpconnectTSEConfiguration }) => getConfigurationValue('whoami', dmpconnectTSEConfiguration);

const getCpxInfoExportUrl = ({ dmpConnectPersistedAppConfiguration }) => getConfigurationValue(
  'cpxInfoExportUrl',
  dmpConnectPersistedAppConfiguration,
);

const setLoginRefererHelper = function* (pathname, hash) {
  if (pathname + hash !== '/') {
    if (pathname === '/') {
      yield put(setLoginReferer(`/search/${hash}`));
    } else {
      yield put(setLoginReferer(pathname + hash));
    }
  }
};

export const checkIfUserIsLoggedIn = function* (payload) {
  const {
    location: { pathname, hash },
    isFirstRendering,
    action,
  } = payload;

  const apiType = yield select(getApiType);
  const loggedIn = yield select(IsUserLoggedIn);

  if (['/debug', '/es-config', '/tse-config', '/authcallback', '/silent-sso', '/login-token', '/logout'].includes(pathname)) {
    return loggedIn;
  }
  if (loggedIn !== true && pathname + hash !== '/') {
    yield call(setLoginRefererHelper, pathname, hash);
    yield put(push('/'));
    return false;
  }

  if (pathname !== '/' && action === 'POP' && apiType !== API_TYPES.REST) {
    // check if the session is still alive
    yield put(getAction(
      commands.sessionExists,
      apiSections.SESSION_EXISTS_SECTION,
      null,
      { silentError: true, contextExtra: { noCardTypeCheck: true } },
    ));

    const result = yield take([
      dmpCommandSuccessContextualizedType(apiSections.SESSION_EXISTS_SECTION),
      dmpCommandFailureContextualizedType(apiSections.SESSION_EXISTS_SECTION),
    ]);


    const exists = yield call(sessionExists, result);
    if (!exists) {
      yield call(setLoginRefererHelper, pathname, hash);
      return false;
    }
  }

  /**
   * Manage the case where the user go back to the root through browser history
   */
  if (!isFirstRendering && pathname === '/' && action === 'POP') {
    yield put(logout());
    return false;
  }
  return loggedIn;
};

export const logoutProcess = function* (action) {
  const { redirectTo = '/' } = action;
  const initialized = yield select(isAppInitialized);
  const apiType = yield select(getApiType);
  if (apiType === API_TYPES.WS) {
    yield put(stopCpxMonitoring());
  }

  const sessionId = yield select(getSessionId);
  if (sessionId) {
    yield put(getAction(
      commands.closeSession,
      apiSections.SESSION_SECTION,
      null,
      {
        synchronous: true,
        noRetries: apiType === API_TYPES.REST,
        silentError: apiType === API_TYPES.REST,
      },
    ));
    yield take(
      [
        dmpCommandSuccessContextualizedType(apiSections.SESSION_SECTION),
        dmpCommandFailureContextualizedType(apiSections.SESSION_SECTION),
      ],
    );
  }
  yield put(clearSection(apiSections.SESSION_SECTION));
  yield put(logoutSuccess());
  window.parent.postMessage({
    type: 'logout',
    desc: 'User has been logged out.',
  }, '*');
  yield put(resetApplication());

  yield put(clearSession());
  if (redirectTo === '/' && initialized) {
    yield put(initApplication());
  }
  const pathname = yield select(getCurrentPathname);
  if (pathname !== redirectTo && pathname !== '/login-token') {
    yield put(push(redirectTo));
  }
};

export const handleReadCpxCard = function* (action) {
  const {
    pinCode, readerNumber, doLogin = true, forceLoginStepTwo = false,
  } = action;

  savePinCode(pinCode);
  yield put(stopCpxMonitoring());
  // CPX card object creation
  yield put(getAction(
    commands.getCpxCard,
    apiSections.CPX_SECTION,
    formatGetCardParams(readerNumber),
    { synchronous: true },
  ));
  const getCpxCardResult = yield take(
    [
      dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_SUCCESS,
      dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_FAILURE,
    ],
  );

  if (getCpxCardResult.type === dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_FAILURE) return;
  // CPX card reading
  yield put(getAction(
    commands.readCpxCard,
    apiSections.CPX_CARD_SECTION,
    { s_pinCode: pinCode },
    {
      synchronous: true,
      contextParams: { code: encodeIns(pinCode) },
    },
  ));
  const readCpxCardResult = yield take(
    [
      dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_SUCCESS,
      dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_FAILURE,
    ],
  );

  if (readCpxCardResult.type === dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_FAILURE) {
    yield put(getAction(
      commands.getCpxStatus,
      apiSections.CPX_STATUS_SECTION,
    ));
    return;
  }

  // si pas de situations d'exercice, erreur
  const { PracticeLocations } = readCpxCardResult.data;
  if (!PracticeLocations || PracticeLocations.length === 0) {
    // return error
    yield put(initApplicationFailure(readCpxCardResult.data));
    const error = createError(
      errorTypes.SoftwareErrors,
      softwareErrors.NO_PRACTICE_LOCATIONS,
    );
    yield put(setModalError({ error }));
  }

  // check card type
  if (Number(env.REACT_APP_PRODUCTON_MODE) === 1) {
    const { i_cpxCardType } = readCpxCardResult.data;

    if (i_cpxCardType !== cpxCardTypes.PROD) {
      // return error
      yield put(initApplicationFailure(readCpxCardResult.data));
      const error = createError(
        errorTypes.SoftwareErrors,
        softwareErrors.DEMO_CPX_IN_PROD,
      );

      yield put(setModalError({
        error,
        details: [createErrorDetails('carte', readCpxCardResult.data)],
      }));
      return;
    }
  }

  // IF success we start monitoring
  yield put(startCpxMonitoring());

  if (doLogin === true) {
    const dmpconnectCPxConfiguration = yield select(getDmpconnectCPxConfiguration);
    const { data: cpxCard } = readCpxCardResult;
    const practiceSetting = yield getUserConfiguration(cpxCard, 'practiceSetting', dmpconnectCPxConfiguration);
    const practiceLocationSetting = yield getUserConfiguration(cpxCard, 'practiceLocationSetting', dmpconnectCPxConfiguration);

    const customPracticeLocationStructureName = yield getUserConfiguration(cpxCard, 'customPracticeLocationStructureName', dmpconnectCPxConfiguration);
    const customPracticeLocationStructureIdType = yield getUserConfiguration(cpxCard, 'customPracticeLocationStructureIdType', dmpconnectCPxConfiguration);
    const customPracticeLocationStructureId = yield getUserConfiguration(cpxCard, 'customPracticeLocationStructureId', dmpconnectCPxConfiguration);
    const customPracticeLocationActivitySector = yield getUserConfiguration(cpxCard, 'customPracticeLocationActivitySector', dmpconnectCPxConfiguration);

    const practiceSettings = yield select(getInteropCodesFromState, 'practiceSettings');
    const billingNumber = getUserConfiguration(cpxCard, 'billingNumber', dmpconnectCPxConfiguration);

    if (
      !forceLoginStepTwo
      && practiceSettings.find(item => item.code === practiceSetting)
      && (
        practiceLocationSetting !== null
        || (
          customPracticeLocationStructureName !== ''
          && customPracticeLocationStructureId !== ''
          && customPracticeLocationActivitySector !== ''
        )
      )
      && billingNumber !== null
    ) {
      yield put(login({
        pinCode,
        practiceSetting,
        cpsPracticeLocationIndice: practiceLocationSetting,
        customPracticeLocationStructureName,
        customPracticeLocationStructureIdType,
        customPracticeLocationStructureId,
        customPracticeLocationActivitySector,
      }));
    } else {
      yield put(setLoginStepTwo(true));
    }
  }
};

export const handleCpxInfoExport = function* () {
  while (true) {
    yield take(dmpconnectUserActionConstants.DMPC_LOGIN_SUCCESS);
    const cpxInfoExportUrl = yield select(getCpxInfoExportUrl);
    const cpxCardResult = yield select(({ dmpconnect: { cpxCard } }) => cpxCard);

    if (cpxInfoExportUrl !== '0') {
      const {
        s_status,
        tooLong,
        updatedAt,
        loading,
        params,
        error,
        ...cleanedCpxInfo
      } = cpxCardResult;
      try {
        const dmpconnectCPxConfiguration = yield select(getDmpconnectCpxConfiguration);
        const practiceLocationSetting = getUserConfiguration(cpxCardResult, 'practiceLocationSetting', dmpconnectCPxConfiguration);
        const practiceSetting = yield getUserConfiguration(cpxCardResult, 'practiceSetting', dmpconnectCPxConfiguration);
        const response = yield call(
          exportCpxInfo,
          {
            ...cleanedCpxInfo,
            i_practiceLocationSetting: practiceLocationSetting,
            i_practiceLocationPracticeSettings: practiceSetting,
          },
          cpxInfoExportUrl,
        );
        if (response.ok) {
          console.log('CPx card info exported with success.');
        }
      } catch (e) {
        console.log('CPx card info export failed.');
      }
    }
  }
};

export const loginProcess = function* (
  {
    auto = false,
    pinCode,
    practiceSetting,
    cpsPracticeLocationIndice,
    customPracticeLocationStructureName,
    customPracticeLocationStructureIdType,
    customPracticeLocationStructureId,
    customPracticeLocationActivitySector,
  },
) {
  yield put(stopCpxMonitoring());

  yield put(setLoginStepTwo(false));
  // TODO make it pure by passing server name to login process ?
  const dmpConfiguration = yield select(getdmpconnectConnectorConfig);
  const serverName = yield getConfigurationValue('tlsiServerName', dmpConfiguration);

  yield put(getAction(
    commands.createTLSiConnector,
    apiSections.TLSI_CONNECTOR_SECTION,
    formatCreateTLSiConnector(
      auto === true ? getSavedPinCode() : pinCode,
      serverName,
      practiceSetting,
      cpsPracticeLocationIndice,
      null,
      customPracticeLocationStructureName,
      customPracticeLocationStructureIdType,
      customPracticeLocationStructureId,
      customPracticeLocationActivitySector,
    ),
    { synchronous: true },
  ));
  const result = yield take(
    [
      dmpCommandSuccessContextualizedType(apiSections.TLSI_CONNECTOR_SECTION),
      dmpCommandFailureContextualizedType(apiSections.TLSI_CONNECTOR_SECTION),
    ],
  );
  if (result.type === dmpCommandFailureContextualizedType(apiSections.TLSI_CONNECTOR_SECTION)) {
    yield put(initApplicationFailure(result.data));
    return;
  }
  savePinCode(null);
  // refresh sessionState to get the cpxType, and continue only after we got it
  yield put(getAction(
    commands.getSessionState,
    apiSections.SESSION_STATE_SECTION,
    null,
    { synchronous: true },
  ));
  yield take(dmpCommandSuccessContextualizedType(apiSections.CPX_CARD_TYPE_SECTION));

  yield put(loginSuccess());

  yield put(startCpxMonitoring());

  const loginReferer = yield select(getLoginReferer);
  if (loginReferer) {
    yield put(clearLoginReferer());
    yield put(push(loginReferer));
  } else {
    // WS par défaut
    const displayConfiguration = yield select(({ dmpconnectDisplayConfiguration }) => dmpconnectDisplayConfiguration);
    const defaultWS = yield getConfigurationValue('defaultWS', displayConfiguration);
    if (defaultWS > 0) {
      yield put(setPanel(PANELS_INDEX[defaultWS]));
    }

    yield put(push('/search'));
  }
};

const getAndSetEsInfos = function* (esId) {
  yield put(getAction(
    commands.getEsInfos,
    apiSections.GET_ES_INFOS,
    { s_esId: esId },
  ));

  const esInfos = yield take([
    dmpCommandSuccessContextualizedType(apiSections.GET_ES_INFOS),
    dmpCommandFailureContextualizedType(apiSections.GET_ES_INFOS),
  ]);

  if (esInfos.type === dmpCommandSuccessContextualizedType(apiSections.GET_ES_INFOS)) {
    yield put(setESConfiguration('practiceLocationName', esInfos.data.s_structureName));
    yield put(setESConfiguration('activitySector', esInfos.data.s_activitySector));
  }
};

const getAndSetUserInfos = function* (esId, userLogin, userPassword) {
  yield put(getAction(
    commands.getUserInfos,
    apiSections.GET_USER_INFOS,
    { s_login: userLogin, s_password: userPassword, s_passwordSha1: userPassword },
  ));

  const userInfos = yield take([
    dmpCommandSuccessContextualizedType(apiSections.GET_USER_INFOS),
    dmpCommandFailureContextualizedType(apiSections.GET_USER_INFOS),
  ]);

  if (userInfos.type === dmpCommandSuccessContextualizedType(apiSections.GET_USER_INFOS)) {
    yield put(setEsUser({
      hpGiven: userInfos.data.s_given,
      hpInternalId: userInfos.data.s_internalId,
      hpName: userInfos.data.s_name,
      hpProfession: userInfos.data.s_professionCode,
      hpProfessionOid: userInfos.data.s_professionOid,
      hpSpeciality: userInfos.data.s_specialty,
      esId,
    }));
  }
};

export const esLoginProcess = function* ({ userLogin, userPassword }) {
  const esCertificate = yield select(getEsCertificate);
  const esId = yield select(getEsId);
  const loginType = yield select(getLoginType);
  const sessionId = yield select(getSessionId);
  const dcParams = yield select(getDcParams);
  const pathname = yield select(getCurrentPathname);
  const dmpConfiguration = yield select(getdmpconnectConnectorConfig);
  const dmpconnectESConfiguration = yield select(getDmpconnectESConfiguration);
  const hpAuthenticationMode = yield getConfigurationValue('hpAuthenticationMode', dmpconnectESConfiguration);
  const serverName = yield getConfigurationValue('tlsiServerName', dmpConfiguration);
  const activitySector = yield getConfigurationValue('activitySector', dmpconnectESConfiguration);
  const practiceSetting = yield getConfigurationValue('practiceSetting', dmpconnectESConfiguration);
  const practiceLocationName = yield getConfigurationValue('practiceLocationName', dmpconnectESConfiguration);
  const tseActive = yield select(getTseActive);
  const tseId = yield select(getTseWhoami);
  const esUser = yield select(getEsUser);
  const productionMode = yield select(getProductionMode);
  const applicationId = yield select(getApplicationId);
  const remoteControlActive = yield select(isRemoteControlActive);
  const remoteControlAppName = yield select(getRemoteControlAppName);

  const openSessionParams = {
    dcParams,
    tseWhoami: tseActive ? tseId : null,
    prodMode: productionMode,
    applicationId: remoteControlActive ? `${applicationId}-${remoteControlAppName.replace(' ', '-')}` : applicationId,
  };

  // fermer la session actuelle et rouvrir en mode ES
  if (sessionId) {
    yield put(getAction(
      commands.closeSession,
      apiSections.SESSION_SECTION,
      null,
      { synchronous: true },
    ));
    yield take(
      [
        dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_SUCCESS,
        dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_FAILURE,
      ],
    );
  }
  yield put(clearSection(apiSections.SESSION_SECTION));

  yield put(getAction(
    commands.openSession,
    apiSections.SESSION_SECTION,
    formatOpenSessionParams(
      loginType === esLoginTypes.CONNECTOR_LOGIN_PASSWD
        ? { esId, ...openSessionParams }
        : { esConfig64: esCertificate, ...openSessionParams },
    ),
    {
      synchronous: true,
      contextExtra: { forceModal: true },
    },
  ));


  const { type, command: { s_commandName } } = yield take([
    dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_SUCCESS,
    dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_FAILURE,
  ]);
  if (type === dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_FAILURE) {
    yield put(setSessionKo());
    if (pathname !== '/') yield put(logout());
  } else if (s_commandName === 'hl_openSession') {
    // set Es Infos
    if (loginType === esLoginTypes.CONNECTOR_LOGIN_PASSWD) {
      yield put(setEsUser(null));
      yield call(getAndSetEsInfos, esId);
    }

    // creation du connecteur
    yield put(getAction(
      commands.createEsTLsiConnector,
      apiSections.TLSI_CONNECTOR_SECTION,
      formatCreateEsTLSiConnector(
        loginType === esLoginTypes.CONNECTOR_LOGIN_PASSWD
          ? {
            loginType,
            serverName,
            practiceLocationName,
            activitySector,
            practiceSetting,
            login: userLogin,
            password: userPassword,
          }
          : {
            loginType,
            serverName,
            practiceLocationName,
            activitySector,
            practiceSetting,
            hpName: esUser.hpName,
            hpGiven: esUser.hpGiven,
            hpProfession: esUser.hpProfession,
            hpProfessionOid: esUser.hpProfessionOid,
            hpSpeciality: ['10', '40', '21'].includes(esUser.hpProfession) ? esUser.hpSpeciality || 'SM24' : esUser.hpSpeciality,
            hpInternalId: esUser.hpInternalId,
            hpAuthenticationMode,
          },
      ),
      { synchronous: true },
    ));

    const result = yield take(
      [
        dmpCommandSuccessContextualizedType(apiSections.TLSI_CONNECTOR_SECTION),
        dmpCommandFailureContextualizedType(apiSections.TLSI_CONNECTOR_SECTION),
      ],
    );
    if (result.type === dmpCommandFailureContextualizedType(apiSections.TLSI_CONNECTOR_SECTION)) {
      yield put(esLoginFailed(result.data));
      return;
    }

    if (loginType === esLoginTypes.CONNECTOR_LOGIN_PASSWD) {
      yield call(getAndSetUserInfos, esId, userLogin, userPassword);
    }

    yield put(esLoginSuccess());
    yield put(initApplicationSuccess());

    const loginReferer = yield select(getLoginReferer);
    if (loginReferer) {
      yield put(clearLoginReferer());
      yield put(push(loginReferer));
    } else {
      // WS par défaut
      const displayConfiguration = yield select(({ dmpconnectDisplayConfiguration }) => dmpconnectDisplayConfiguration);
      const defaultWS = yield getConfigurationValue('defaultWS', displayConfiguration);
      if (defaultWS > 0) {
        yield put(setPanel(PANELS_INDEX[defaultWS]));
      }

      yield put(push('/search'));
    }
  }
};
